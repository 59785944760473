export const PERMISSION = {
  Read: "read",
  Create: "create",
  Update: "update",
  Delete: "delete",
};

export const RIGHTS = {
  Any: "any",
  ALL: "all",
};

//valus of each key represents the "key" for translation
export const FIELD_VALIDATION_TYPE = {
  Required: "PleaseEnter",
  Invalid: "PleaseEnterValid",
  CharectersAndNumbersOnly: "CharactersAndNumbersOnly",
  CharectersOnly: "CharactersOnly",
};

export const TRANSLATION_NAMESPACE = {
  Common: "common",
  Validation: "validation",
  Account: "account",
  Product: "product",
  Setup: "setup",
  Report: "report",
  Sell: "sell",
};

export const SCOPE = {
  Dashboard: "dashboard",
  Sell: "sell",
  SellQuickKeysSetup: "sell_quickkeyssetup",
  Purchase: "purchase",
  Products: "products",
  ProductsCategories: "products_categories",
  ProductsStockControl: "products_stockcontrol",
  ProductsTags: "products_tags",
  ProductsBrands: "products_brands",
  Suppliers: "suppliers",
  ReportsReceivables: "reports_receivables",
  ReportsTaxPayables: "reports_taxpayables",
  ReportsProfitAndLoss: "reports_profitandloss",
  ReportsSales: "reports_sales",
  ReportsInventory: "reports_inventory",
  Customers: "customers",
  SetupSettings: "setup_settings",
  SetupSalesTaxes: "setup_salestaxes",
  User: "user",
};

export const SESSIONSTORAGE = {
  uniqueCompanyName: "uniqueCompanyName",
  isSellWorkerInProgress: "isSellWorkerInProgress",
  saleSyncRunUtcDateTime: "saleSyncRunUtcDateTime",

  quickKey: "quickKey",
};

export const LOCALSTORAGE = {
  offlineProductsSyncUtcDateTime: "offlineProductsSyncUtcDateTime",
  offlineCustomersSyncUtcDateTime: "offlineCustomersSyncUtcDateTime",
  offlineSyncUtcDateTime: "offlineSyncUtcDateTime",
  saleTaxes: "saleTaxes",
  printSettings: "printSettings",
  companySettings: "companySettings",
  notifications: "notifications",
};

//TODO: change below to integer values
export const PAYMENTMETHODS = {
  CASH: 1,
  CREDITCARD: 2,
  CREDIT: 3,
};

export const PAYMENTSTATUS = {
  COMPLETED: 1,
};

export const PRODUCTTYPES = {
  StandardProduct: 1,
  ProductWithVariants: 2,
};

export const HTTPSTATUS = {
  ValidationError: "400",
  BadRequest: "400",
  InternalError: "500",
  AuthenticationError: "401",
  AuthNetworkError: "auth/network-request-failed",
};

export const REGEX = {
  Name: "^[A-Za-z0-9-_ ]*$",
  Phone: "^[+]*[0-9]*$",
  PositiveNumber: "^[+]?([1-9]\\d*(\\.\\d+)?|0\\.\\d+|[1-9]\\d*)$",
};

export const OFFLINESTATE = {
  NotInitiated: 0,
  Ready: 1,
  InProgress: 2,
};

export const GROUPBY_DATE_ENUM = {
  Day: 0,
  Month: 1,
};
