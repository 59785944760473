import React, { useContext } from "react";

import {
  LineChartOutlined,
  PieChartOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  TagOutlined,
  TeamOutlined,
  ContactsOutlined,
  DollarOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

import { Layout, Menu } from "antd";
import "./index.scss";
import Logo from "../Logo";
import Accordion from "./Accordion";
import FunctionPoint from "../FunctionPoint";
import { hasRights } from "../../_utils";
import { PERMISSION, SCOPE } from "../../_utils/consts";
import { Trans, withNamespaces } from "react-i18next";
import { constructUrl } from "~/config";
import { withRouter } from "react-router-dom";
import { AuthContext } from "~/_auth/Auth";
import VersionUpdateButton from "~/components/Common/Button/VersionUpdateButton";

const { Sider } = Layout;
const SubMenu = Menu.SubMenu;

// submenu keys of first level
const rootSubmenuKeys = [
  "dashboard",
  "/sell",
  "/purchase",
  "/products",
  "/reports",
  "/customers",
  "/setup",
  "/suppliers",
];
const keySeparator = "/"; // sell/sell (separator: "/")
//const defaultSelectedKey = `sell`;
const defaultOpenKeys = []; //default panel to be opened e-g: "sell"

const MenuItems = (props) => {
  //key for submenu should be rootKey{Separator}subMenuKey e-g: sell/history (separator: '/')
  const { onMenuItemClick, openKeys, isOnline = true, ...otherProps } = props;
  const { currentUser: user } = useContext(AuthContext);
  return (
    <Menu openKeys={openKeys} {...otherProps}>
      {hasRights(SCOPE.Dashboard, [PERMISSION.Read], {
        user: user,
      }) && (
        <Menu.Item
          key="dashboard"
          onClick={onMenuItemClick}
          disabled={isOnline === false}
        >
          <PieChartOutlined />
          <span>
            {" "}
            <Trans i18nKey="Dashboard">Dashboard</Trans>
          </span>
        </Menu.Item>
      )}
      {hasRights("sell*", [PERMISSION.Read], { user: user }) && (
        <SubMenu
          key="/sell"
          title={
            <span>
              <ShoppingCartOutlined />
              <span>
                <Trans i18nKey="Sell">Sell</Trans>
              </span>
            </span>
          }
        >
          {hasRights(SCOPE.Sell, [PERMISSION.Create], {
            user: user,
          }) && (
            <Menu.Item key={`sell`} onClick={onMenuItemClick}>
              <Trans i18nKey="Sell">Sell</Trans>
            </Menu.Item>
          )}
          {hasRights(SCOPE.Sell, [PERMISSION.Read], { user: user }) && (
            <Menu.Item
              key={`sell${keySeparator}history`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="History">History</Trans>
            </Menu.Item>
          )}
          {hasRights(SCOPE.SellQuickKeysSetup, [PERMISSION.Create], {
            user: user,
          }) && (
            <Menu.Item
              key={`sell${keySeparator}quickkeyssetup`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="quickkeyssetup">Quick keys setup</Trans>
            </Menu.Item>
          )}
        </SubMenu>
      )}
      {hasRights("purchase*", [PERMISSION.Read], { user: user }) && (
        <SubMenu
          key="/purchase"
          disabled={isOnline === false}
          title={
            <span>
              <DollarOutlined />
              <span>
                <Trans i18nKey="Purchase">Purchase</Trans>
              </span>
            </span>
          }
        >
          {hasRights(SCOPE.Purchase, [PERMISSION.Read], { user: user }) && (
            <Menu.Item
              key={`purchase${keySeparator}overview`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="Overview">Overview</Trans>
            </Menu.Item>
          )}
          {hasRights(SCOPE.Purchase, [PERMISSION.Create], {
            user: user,
          }) && (
            <Menu.Item
              key={`purchase`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="Purchase">Purchase</Trans>
            </Menu.Item>
          )}
        </SubMenu>
      )}
      {hasRights("products*", [PERMISSION.Read], { user: user }) && (
        <SubMenu
          key="/products"
          disabled={isOnline === false}
          title={
            <span>
              <TagOutlined />
              <span>
                <Trans i18nKey="Products">Products</Trans>
              </span>
            </span>
          }
        >
          {hasRights(SCOPE.Products, [PERMISSION.Read], {
            user: user,
          }) && (
            <Menu.Item
              key={`products`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="Overview">Overview</Trans>
            </Menu.Item>
          )}
          {hasRights(SCOPE.Products, [PERMISSION.Create], {
            user: user,
          }) && (
            <Menu.Item
              key={`products${keySeparator}product`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="Product">Product</Trans>
            </Menu.Item>
          )}

          {hasRights(SCOPE.ProductsCategories, [PERMISSION.Read], {
            user: user,
          }) && (
            <Menu.Item
              key={`products${keySeparator}categories`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="Categories">Categories</Trans>
            </Menu.Item>
          )}
          {/* {hasRights(SCOPE.ProductsStockControl, [PERMISSION.Read], {
                user: user,
              }) && (
                <Menu.Item
                  key={`products${keySeparator}stockcontrol`}
                  onClick={onMenuItemClick}
                >
                  <Trans i18nKey="StockControl">Stock control</Trans>
                </Menu.Item>
              )}
              {hasRights(SCOPE.ProductsTags, [PERMISSION.Read], {
                user: user,
              }) && (
                <Menu.Item
                  key={`products${keySeparator}tags`}
                  onClick={onMenuItemClick}
                >
                  <Trans i18nKey="Tags">Tags</Trans>
                </Menu.Item>
              )} */}
          {hasRights(SCOPE.ProductsBrands, [PERMISSION.Read], {
            user: user,
          }) && (
            <Menu.Item
              key={`products${keySeparator}brands`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="Brands">Brands</Trans>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {hasRights("suppliers*", [PERMISSION.Read], { user: user }) && (
        <SubMenu
          key="/suppliers"
          disabled={isOnline === false}
          title={
            <span>
              <ContactsOutlined />
              <span>
                <Trans i18nKey="suppliers">Suppliers</Trans>
              </span>
            </span>
          }
        >
          {hasRights(SCOPE.Suppliers, [PERMISSION.Read], {
            user: user,
          }) && (
            <Menu.Item
              key={"suppliers"}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="Overview">{"Overview"}</Trans>
            </Menu.Item>
          )}
          {hasRights(SCOPE.Suppliers, [PERMISSION.Read], {
            user: user,
          }) && (
            <Menu.Item
              key={`suppliers${keySeparator}supplier`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="Supplier">Supplier</Trans>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {/* {hasRights("reports*", [PERMISSION.Read], { user: user }) && (
        <SubMenu
          key="reports"
          disabled={isOnline === false}
          title={
            <span>
              <LineChartOutlined />
              <span>
                <Trans i18nKey="Reports">Reports</Trans>
              </span>
            </span>
          }
        >
          {hasRights(SCOPE.ReportsProfitAndLoss, [PERMISSION.Read], {
            user: user,
          }) && (
            <Menu.Item
              key={`reports${keySeparator}profitandloss`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="ProfitAndLoss">{"Profit & Loss"}</Trans>
            </Menu.Item>
          )}
          {hasRights(SCOPE.ReportsSales, [PERMISSION.Read], {
            user: user,
          }) && (
            <Menu.Item
              key={`reports${keySeparator}sales`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="Sales">Sales</Trans>
            </Menu.Item>
          )}
          {hasRights(SCOPE.ReportsInventory, [PERMISSION.Read], {
            user: user,
          }) && (
            <Menu.Item
              key={`reports${keySeparator}inventory`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="Inventory">Inventory</Trans>
            </Menu.Item>
          )}
        </SubMenu>
      )} */}

      {hasRights("customers*", [PERMISSION.Read], { user: user }) && (
        <SubMenu
          key="/customers"
          disabled={isOnline === false}
          title={
            <span>
              <TeamOutlined />
              <span>
                <Trans i18nKey="Customers">Customers</Trans>
              </span>
            </span>
          }
        >
          {hasRights(SCOPE.Customers, [PERMISSION.Read], {
            user: user,
          }) && (
            <Menu.Item
              key={`customers`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="Overview">Overview</Trans>
            </Menu.Item>
          )}
          {hasRights(SCOPE.Customers, [PERMISSION.Create], {
            user: user,
          }) && (
            <Menu.Item
              key={`customers${keySeparator}customer`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="Customer">Customer</Trans>
            </Menu.Item>
          )}
          {hasRights(SCOPE.Customers, [PERMISSION.Read], {
            user: user,
          }) && (
            <Menu.Item
              key={`customers${keySeparator}payments`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="Payments">Payments</Trans>
            </Menu.Item>
          )}
        </SubMenu>
      )}
      {hasRights("reports*", [PERMISSION.Read], { user: user }) && (
        <SubMenu
          key="/reports"
          disabled={isOnline === false}
          title={
            <span>
              <FileTextOutlined />
              <span>
                <Trans i18nKey="reports">Reports</Trans>
              </span>
            </span>
          }
        >
          {hasRights(SCOPE.ReportsSales, [PERMISSION.Read], {
            user: user,
          }) && (
            <Menu.Item
              key={`reports${keySeparator}saledetails`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="SaleDetails">{"Sales report"}</Trans>
            </Menu.Item>
          )}
          {hasRights(SCOPE.ReportsInventory, [PERMISSION.Read], {
            user: user,
          }) && (
            <Menu.Item
              key={`reports${keySeparator}stockdetails`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="StockDetails">{"Stock report"}</Trans>
            </Menu.Item>
          )}
          {hasRights(SCOPE.ReportsReceivables, [PERMISSION.Read], {
            user: user,
          }) && (
            <Menu.Item
              key={`reports${keySeparator}receivables`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="ReceivablesReport">{"Receivables report"}</Trans>
            </Menu.Item>
          )}
          {hasRights(SCOPE.ReportsReceivables, [PERMISSION.Read], {
            user: user,
          }) && (
            <Menu.Item
              key={`reports${keySeparator}receivablesdetails`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="ReceivablesDetails">{"Receivable details"}</Trans>
            </Menu.Item>
          )}
          {hasRights(SCOPE.ReportsTaxPayables, [PERMISSION.Read], {
            user: user,
          }) && (
            <Menu.Item
              key={`reports${keySeparator}taxpayables`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="TaxPayables">{"Tax report"}</Trans>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {hasRights("setup*", [PERMISSION.Read], { user: user }) && (
        <SubMenu
          key="/setup"
          disabled={isOnline === false}
          title={
            <span>
              <SettingOutlined />
              <span>
                <Trans i18nKey="Setup">Setup</Trans>
              </span>
            </span>
          }
        >
          {hasRights(SCOPE.SetupSalesTaxes, [PERMISSION.Create], {
            user: user,
          }) && (
            <Menu.Item
              key={`setup${keySeparator}salestaxes`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="SalesTaxes">Sales Taxes</Trans>
            </Menu.Item>
          )}
          {hasRights(SCOPE.SetupSettings, [PERMISSION.Create], {
            user: user,
          }) && (
            <Menu.Item
              key={`setup${keySeparator}settings`}
              onClick={onMenuItemClick}
              disabled={isOnline === false}
            >
              <Trans i18nKey="Settings">Settings</Trans>
            </Menu.Item>
          )}
        </SubMenu>
      )}
    </Menu>
  );
};

class SiderMenu extends React.Component {
  constructor(props) {
    super(props);
    this.onMenuItemClick = this.onMenuItemClick.bind();
    this.getKey = this.getKey.bind();
    this.state = { collapseSideMenu: props.collapsed };
  }
  onMenuItemClick = ({ item, key, keyPath, domEven }) => {
    const { history, match } = this.props;
    history.push(constructUrl({ match, to: `/${key}` })); //history is injected from hoc withRouter
  };
  getKey = () => {
    const { location = {} } = this.props;
    const path = location.pathname;

    let key = path ? path.substring(4).toLowerCase() : "";
    return key.endsWith("/") ? key.substring(0, key.length - 1) : key;
  };
  componentDidUpdate() {
    const _this = this;
    const { collapsed } = this.props;
    const { collapseSideMenu } = this.state;
    if (collapsed !== collapseSideMenu) {
      //had to add delayed to wait for open keys to be closed .. issue with antdSider and collapsed & openKeys
      //If the sidemenu is collapsed and openKeys are set to empty even then the menu items appear as opened
      setTimeout(function() {
        _this.setState({
          collapseSideMenu: collapsed,
        });
      }, 250);
    }
  }

  render() {
    const { accordion, collapsed, isOnline = true } = this.props;
    const { collapseSideMenu } = this.state;

    const menuItemKey = this.getKey();
    let menuOptions = {
      theme: "light",
      mode: "inline",
      defaultSelectedKeys: [menuItemKey],
      selectedKeys: [menuItemKey],
      defaultOpenKeys: defaultOpenKeys,
      onMenuItemClick: this.onMenuItemClick,
      isOnline: isOnline,
    };
    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed === true ? collapseSideMenu : false}
        className="sider"
        theme="light"
        width={220}
      >
        <Logo />
        {accordion === true ? (
          <Accordion
            rootSubmenuKeys={rootSubmenuKeys}
            defaultSelectedKey={this.getKey()}
            defaultOpenKeys={defaultOpenKeys}
            collapsed={collapsed}
            keySeparator={keySeparator}
          >
            {(options) => <MenuItems {...menuOptions} {...options} />}
          </Accordion>
        ) : (
          <MenuItems {...menuOptions} />
        )}
        {isOnline === true && <VersionUpdateButton small={collapsed} />}
      </Sider>
    );
  }
}

export default withRouter(withNamespaces()(SiderMenu));
