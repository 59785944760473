import { PERMISSION } from "../../_utils/consts";

export const user = {
  UserID: "0000-111-222",
  isAuthenticated: true,
  Name: "Asim",
  Role: "Admin",
  FunctionPoints: [
    {
      Name: "dashboard",
      Permission: PERMISSION.Read,
    },
    {
      Name: "sell",
      Permission: PERMISSION.Read,
    },
    {
      Name: "sell",
      Permission: PERMISSION.Create,
    },
    {
      Name: "purchase",
      Permission: PERMISSION.Read,
    },
    {
      Name: "purchase",
      Permission: PERMISSION.Create,
    },
    {
      Name: "sell_quickkeyssetup",
      Permission: PERMISSION.Create,
    },
    {
      Name: "products",
      Permission: PERMISSION.Read,
    },
    {
      Name: "products_product",
      Permission: PERMISSION.Create,
    },
    {
      Name: "products_brands",
      Permission: PERMISSION.Read,
    },
    {
      Name: "products_brands",
      Permission: PERMISSION.Create,
    },
    {
      Name: "products_categories",
      Permission: PERMISSION.Read,
    },
    {
      Name: "products_categories",
      Permission: PERMISSION.Create,
    },
    {
      Name: "products_stockcontrol",
      Permission: PERMISSION.Read,
    },
    {
      Name: "products_tags",
      Permission: PERMISSION.Read,
    },
    {
      Name: "suppliers",
      Permission: PERMISSION.Read,
    },
    {
      Name: "suppliers_supplier",
      Permission: PERMISSION.Create,
    },
    {
      Name: "reports_profitandloss",
      Permission: PERMISSION.Read,
    },
    {
      Name: "reports_sales",
      Permission: PERMISSION.Read,
    },
    {
      Name: "reports_inventory",
      Permission: PERMISSION.Read,
    },
    {
      Name: "reports_receivables",
      Permission: PERMISSION.Read,
    },
    {
      Name: "reports_stockDetails",
      Permission: PERMISSION.Read,
    },
    {
      Name: "reports_saleDetails",
      Permission: PERMISSION.Read,
    },
    {
      Name: "reports_taxpayables",
      Permission: PERMISSION.Read,
    },
    {
      Name: "customers",
      Permission: PERMISSION.Read,
    },
    {
      Name: "customers",
      Permission: PERMISSION.Create,
    },
    {
      Name: "setup_settings",
      Permission: PERMISSION.Create,
    },
    {
      Name: "setup_users",
      Permission: PERMISSION.Read,
    },
    {
      Name: "setup_alerts",
      Permission: PERMISSION.Read,
    },
    {
      Name: "setup_registers",
      Permission: PERMISSION.Create,
    },
    {
      Name: "setup_salestaxes",
      Permission: PERMISSION.Create,
    },
    {
      Name: "setup_settings",
      Permission: PERMISSION.Create,
    },
    {
      Name: "user",
      Permission: PERMISSION.Create,
    },
  ],
};

export const cashier = {
  UserID: "0000-111-222",
  isAuthenticated: true,
  Name: "Asim",
  Role: "Cashier",
  FunctionPoints: [
    {
      Name: "sell",
      Permission: PERMISSION.Read,
    },
    {
      Name: "sell",
      Permission: PERMISSION.Create,
    },
    {
      Name: "sell_quickkeyssetup",
      Permission: PERMISSION.Create,
    },
    {
      Name: "customers",
      Permission: PERMISSION.Read,
    },
    {
      Name: "customers",
      Permission: PERMISSION.Create,
    },
  ],
};
