import React, { useContext } from "react";
import { defaultBaseUrl } from "~/config";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "~/_auth/Auth";
import { convertDateToUtcIso, hasRights } from "~/_utils";
import Subscription from "~/pages/Subscription";
import moment from "moment";
import { MessageOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";

const NoRights = ({}) => {
  return (
    <Row className="norights-container">
      <Col
        xs={24}
        style={{
          textAlign: "center",
          minHeight: "200px",
          paddingTop: "150px",
          fontWeight: "bold",
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <MessageOutlined
              style={{ fontSize: "20px", paddingRight: "5px" }}
            />
            <span style={{ fontSize: "15px" }}>You don't have rights</span>
          </Col>
          <Col xs={24}>
            <span style={{ fontSize: "12px" }}>Use side menu to navigate</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const PrivateLayoutRoute = ({
  component: ComponentToRender,
  layout: Layout,
  scope = "",
  permission = "",
  ...rest
}) => {
  if (!ComponentToRender || !Layout) {
    return <></>;
  }

  const { currentUser = {} } = useContext(AuthContext);
  const isSubscriptionExpiryClaimExists =
    currentUser.claims &&
    currentUser.claims.subscriptionExpiryDateUtc !== undefined &&
    currentUser.claims.subscriptionExpiryDateUtc !== "";

  const todayUtc = convertDateToUtcIso(new Date());
  const subscriptionRemainingDays =
    isSubscriptionExpiryClaimExists === true
      ? moment(currentUser.claims.subscriptionExpiryDateUtc).diff(
          moment(todayUtc),
          "days"
        ) + 1
      : undefined;

  const isSubscriptionExpired =
    subscriptionRemainingDays !== undefined && subscriptionRemainingDays <= 0
      ? true
      : false;

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        currentUser.isAuthenticated === true ? (
          <Layout
            {...matchProps}
            subscription={{
              isSubscriptionExpired: isSubscriptionExpired,
              subscriptionRemainingDays: subscriptionRemainingDays,
            }}
          >
            {isSubscriptionExpired === true ? (
              <Subscription />
            ) : scope !== "" &&
              permission !== "" &&
              !hasRights(scope, [permission], { user: currentUser }) ? (
              <NoRights />
            ) : (
              <ComponentToRender />
            )}
          </Layout>
        ) : (
          <Redirect to={defaultBaseUrl + "/Login"} />
        )
      }
    />
  );
};

export default PrivateLayoutRoute;
