import React, { useContext } from "react";
import PriceLabel from "../../Common/PriceLabel";
import InputDecimal from "../../Common/InputDecimal";
import { DeleteTwoTone } from "@ant-design/icons";
import { Row, Col, Input, Collapse, InputNumber } from "antd";
import Button from "~/components/Common/Button";
import ProductDetailModal from "~/components/Sell/ProductDetail/ProductDetailModal";
import { translate, hasRights } from "~/_utils";
import { TRANSLATION_NAMESPACE, PERMISSION } from "~/_utils/consts";
import { AuthContext } from "~/_auth/Auth";

const ProductHeader = (props) => {
  const {
    name,
    quantity,
    variant,
    totalProductPrice,
    totalProductOriginalPrice,
    onProductDelete,
    productIndex,
    readonly = false,
  } = props;

  return (
    <div className="productHeader">
      <Row gutter={12}>
        <Col xs={24} className="productContainer">
          <span className="productName">{`${name} x ${quantity}`}</span>
          <div className="priceContainer">
            <PriceLabel value={totalProductPrice} />
            {!readonly && (
              <DeleteTwoTone
                onClick={(event) => {
                  onProductDelete(productIndex);
                  event.stopPropagation();
                }}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} className="subSection">
          <span className="variant">{variant}</span>
          <div className="priceContainer">
            {totalProductOriginalPrice !== totalProductPrice && (
              <PriceLabel value={totalProductOriginalPrice} />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

class ProductHeaderExpandSection extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.quantityFieldRef = null;
  }
  componentDidMount() {
    this.quantityFieldRef &&
      this.quantityFieldRef.focus &&
      this.quantityFieldRef.focus();
  }
  render() {
    const { TextArea } = Input;
    const { currentUser: user } = this.context;
    const {
      price,
      quantity,
      discountPercent,
      onPriceChange,
      onQuantityChange,
      onDiscountChange,
      onShowDetail,
      onTab,
    } = this.props;

    return (
      <div>
        <Row gutter={12}>
          <Col xs={8}>
            <span>{`${translate("Quantity", {
              namespace: TRANSLATION_NAMESPACE.Sell,
            })}`}</span>
          </Col>
          <Col xs={8}>
            <span>{`${translate("UnitPrice", {
              namespace: TRANSLATION_NAMESPACE.Sell,
            })}`}</span>
          </Col>
          <Col xs={8}>
            <span>{`${translate("Discount", {
              namespace: TRANSLATION_NAMESPACE.Sell,
            })}(%)`}</span>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={8}>
            <InputDecimal
              defaultValue={quantity !== undefined ? quantity : 1}
              value={quantity !== undefined ? quantity : 1}
              onChange={onQuantityChange}
              setRef={(el) => {
                this.quantityFieldRef = el;
              }}
              onKeyDown={(event) => {
                if (event.key === "Tab" && event.shiftKey === true) {
                  onTab(event);
                }
              }}
            />
          </Col>
          <Col xs={8}>
            <InputDecimal
              step={0.01}
              defaultValue={price}
              value={price}
              onChange={onPriceChange}
            />
          </Col>
          <Col xs={8}>
            <InputDecimal
              step={0.01}
              defaultValue={discountPercent}
              value={discountPercent}
              onChange={onDiscountChange}
              onKeyDown={(event) => {
                if (event.key === "Tab" && event.shiftKey === false) {
                  onTab(event);
                }
              }}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24}>
            {hasRights("products*", [PERMISSION.Read], { user: user }) && (
              <Button
                type="link"
                onClick={onShowDetail}
                style={{ padding: "0px" }}
              >
                {`${translate("ShowDetails", {
                  namespace: TRANSLATION_NAMESPACE.Sell,
                })}`}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productDetailModalVisible: false,
      showProductDetailBarcode: "",
    };
    this.onShowProductDetail = this.onShowProductDetail.bind();
    this.onTab = this.onTab.bind();
  }
  onShowProductDetail = (props = {}) => {
    const { product = {} } = props;
    this.setState({
      showProductDetailBarcode: product.barcode,
      productDetailModalVisible: true,
    });
  };
  onTab = (event, index) => {
    if (!event || event.key !== "Tab") {
      return;
    }
    const _this = this;
    const isShiftTab = event.shiftKey === true;
    const { products = [] } = this.props;
    const nextIndex = isShiftTab === true ? index - 1 : index + 1;
    const nextProduct = products[nextIndex] || {};
    const nextProductId = nextProduct.productId;
    if (!nextProductId) {
      return;
    }

    event.preventDefault();

    const activeKey = `${nextProductId}_${nextIndex}`;
    this.setState({
      activeKey: `${activeKey}`,
    });
  };
  componentDidUpdate(prevProps, prevState) {
    const {
      expand: shouldExpandLastProduct = false,
      products = [],
    } = this.props;
    const { activeKey = "" } = prevState;

    const lastIndex = products.length - 1;
    const lastProductId = (products[lastIndex] || {}).productId;

    if (shouldExpandLastProduct === false || !lastProductId) {
      return;
    }
    const newActiveKey = `${lastProductId}_${lastIndex}`;

    if (activeKey === newActiveKey) {
      return;
    }

    this.setState({
      activeKey: `${newActiveKey}`,
    });
  }
  render() {
    const { Panel } = Collapse;
    const {
      productDetailModalVisible = false,
      showProductDetailBarcode = "",
      activeKey = "",
    } = this.state;
    const {
      products,
      onProductDelete,
      onPriceChange,
      onQuantityChange,
      onDiscountChange,
      readonly = false,
    } = this.props;
    const _this = this;
    return (
      <>
        <Collapse
          accordion
          destroyInactivePanel={true}
          activeKey={activeKey}
          onChange={(key) => {
            _this.setState({ activeKey: key });
          }}
        >
          {products.map((product, index) => {
            const quantity = product.quantity;
            const totalProductPrice = product.price * quantity;
            const totalProductOriginalPrice = product.originalPrice * quantity;
            return (
              <Panel
                showArrow={!readonly}
                disabled={readonly}
                header={ProductHeader({
                  name: product.name,
                  quantity: quantity,
                  variant: product.variant,
                  totalProductPrice: totalProductPrice,
                  totalProductOriginalPrice: totalProductOriginalPrice,
                  id: product.productId,
                  onProductDelete: onProductDelete,
                  productIndex: index,
                  readonly: readonly,
                })}
                key={`${product.productId}_${index}`}
              >
                <ProductHeaderExpandSection
                  quantity={quantity}
                  price={product.price}
                  discountPercent={product.discountPercent}
                  onPriceChange={(e) => onPriceChange(e, index)}
                  onQuantityChange={(e) => onQuantityChange(e, index)}
                  onDiscountChange={(e) => onDiscountChange(e, index)}
                  onShowDetail={() =>
                    _this.onShowProductDetail({ product: product })
                  }
                  onTab={(e) => _this.onTab(e, index)}
                />
              </Panel>
            );
          })}
        </Collapse>
        <ProductDetailModal
          barcode={showProductDetailBarcode}
          visible={productDetailModalVisible}
          onCancel={() =>
            this.setState({
              productDetailModalVisible: false,
              showProductDetailBarcode: "",
            })
          }
        />
      </>
    );
  }
}

Products.defaultProps = {
  products: [],
};

export default Products;
