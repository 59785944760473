import React from "react";
import {
  UserOutlined,
  MailOutlined,
  LockOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { Row, Col, Input, Form, Divider, Typography, Select } from "antd";
import queryString from "query-string";
import { Trans, withNamespaces } from "react-i18next";
import { getUser, saveUser, getRoles } from "~/actions/userActions";
import Button from "~/components/Common/Button";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { propName } from "~/_utils";
import FormItem from "~/components/Form/FormItem";
import { PageTitle } from "~/components/Common/Text";
import Prompt from "~/components/Common/Prompt";
import viewModel from "./viewModel";
import "./index.scss";

const { Title, Text } = Typography;
const { Option } = Select;

class User extends React.Component {
  constructor(props) {
    super(props);

    const { user = {} } = props;
    this.state = { user: user, formRef: null, roles: [] };

    this.handleSubmit = this.handleSubmit.bind();
    this.onFinishFailed = this.onFinishFailed.bind();
    this.setFormRef = this.setFormRef.bind();
    this.getUser = this.getUser.bind();
  }
  getUser = (id) => {
    const _this = this;

    getUser({ id: id })
      .then(function(result) {
        _this.setState(
          {
            user: result.data,
            isEditMode: true, //getUser is only called in edit mode
          },
          _this.state.formRef.setFieldsValue(result.data)
        );
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  handleSubmit = (values) => {
    this.setState({
      loading: true,
    });
    const { saveUser, onSave } = this.props;
    const { formRef, user } = this.state;
    const _this = this;

    const updatedValues = {
      ...(values || {}),
      userId: user.userId,
      externalId: user.externalId,
    };

    saveUser(updatedValues)
      .then(function(result) {
        onSave && onSave(result);

        _this.setState({
          loading: false,
          isFormChanged: false,
        });
      })
      .catch(function(err) {
        _this.setState({
          loading: false,
        });

        if (err.validationErrors && err.validationErrors.length > 0) {
          formRef.setFields(err.validationErrors);
        }
      });
  };
  componentDidMount() {
    const { location } = this.props;
    const _this = this;
    const queryParams = queryString.parse(location.search);
    const userId = queryParams.userId;
    userId && this.getUser(userId);
    getRoles({ enablePaging: false })
      .then(function(result) {
        const { data } = result;
        _this.setState({
          roles: [...data],
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  }

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };

  render() {
    const _this = this;
    const { showTitle = true, size } = this.props;
    const {
      user = {},
      formRef,
      loading = false,
      isFormChanged = false,
      isEditMode = false,
      roles = [],
    } = this.state;
    const initialValues = {
      displayName: user.displayName,
      email: user.email,
      userName: user.userName,
      password: user.password || "",
      roleId: user.roleId,
    };

    const windowSizeLg = { xs: 24, md: 18, lg: 16, xl: 12 };
    const windowSizeMd = { xs: 24 };
    const windowSize =
      size && size.toUpperCase() === "MD" ? windowSizeMd : windowSizeLg;

    return (
      <div className="user-form-container">
        <PageTitle>User</PageTitle>
        <Divider />
        <br />

        <Row>
          <Col {...windowSize}>
            <Form
              validateTrigger="onBlur"
              onFinish={this.handleSubmit}
              onFinishFailed={this.onFinishFailed}
              className="user-form"
              initialValues={initialValues}
              ref={this.setFormRef}
              onFieldsChange={() => {
                isFormChanged !== true &&
                  _this.setState({ isFormChanged: true });
              }}
            >
              <Prompt when={isFormChanged} />
              <Row gutter={[16, 8]}>
                <Col xs={24} md={12}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.displayName)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.displayName)]
                    }
                  >
                    {({ options }) => (
                      <Input prefix={<IdcardOutlined />} {...options} />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} md={12}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.email)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.email)]
                    }
                  >
                    {(options) => (
                      <Input prefix={<MailOutlined />} {...options} />
                    )}
                  </FormItem>
                </Col>

                <Col xs={24} md={12}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.userName)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.userName)]
                    }
                  >
                    {(options) => (
                      <Input prefix={<UserOutlined />} {...options} />
                    )}
                  </FormItem>
                </Col>

                <Col xs={24} md={12}>
                  <FormItem
                    form={formRef}
                    propertyName={propName(initialValues, (o) => o.password)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.password)]
                    }
                  >
                    {({ options }) => (
                      <Input.Password
                        prefix={<LockOutlined />}
                        placeholder={isEditMode === true ? "(unchanged)" : ""}
                        {...options}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} md={12}>
                  <FormItem
                    propertyName={propName(initialValues, (o) => o.roleId)}
                    viewModelKeyValue={
                      viewModel[propName(initialValues, (o) => o.roleId)]
                    }
                    form={formRef}
                  >
                    {(options, setValue) => (
                      <Select
                        allowClear={false}
                        style={{ width: "100%" }}
                        placeholder={options.placeholder}
                        onChange={(value) => {
                          setValue(value);
                        }}
                      >
                        {roles.map((role, index) => {
                          const key = `${role.roleId}${index}`;
                          return (
                            <Option key={key} value={role.roleId}>
                              {role.title}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>
                </Col>

                <Col xs={24}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="save-button"
                      loading={loading}
                    >
                      <Trans i18nKey="Save">Save</Trans>
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { saveUser })(
  withRouter(withNamespaces()(User))
);
