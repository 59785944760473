import {
  recordSavedSuccess,
  recordSavedFailed,
  recordDeleteSuccess,
  recordDeleteFailed,
} from "./generalActions";
import { mutate, query } from "~/_utils/httpClient";

const SAVE_SUPPLIER_COLUMNS = `
    supplierId
`;

const GET_SUPPLIERS_COLUMNS = `
      supplierId,
      name
      email
      phone
      address
      description
      `;
const GET_SUPPLIER_COLUMNS = GET_SUPPLIERS_COLUMNS;

export const saveSupplier = (supplier) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    try {
      let updatedSupplier = supplier;
      mutate({
        command: supplier.supplierId ? "updateSupplier" : "createSupplier",
        commandType: supplier.supplierId
          ? "UpdateSupplierCommandInput!"
          : "CreateSupplierCommandInput!",
        data: supplier,
        columns: SAVE_SUPPLIER_COLUMNS,
      })
        .then(function(result) {
          updatedSupplier.supplierId = result.data.supplierId;
          dispatch(recordSavedSuccess("supplier", updatedSupplier));
          resolve(updatedSupplier);
        })
        .catch(function(err) {
          dispatch(recordSavedFailed("supplier", err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const getSupplier = (options) => {
  const { id } = options;
  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_SUPPLIER_COLUMNS,
        entity: "supplier",
        id: id,
        idType: "Uuid!",
      }).then(function(result) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getSuppliers = (options = {}) => {
  const { filterValues, sort, pageInfo = {}, enablePaging = true } = options;

  return new Promise(function(resolve, reject) {
    try {
      query({
        columns: GET_SUPPLIERS_COLUMNS,
        entity: "suppliers",
        filter: filterValues,
        filterType: "SupplierDtoFilterInput",
        sort: sort,
        sortType: "[SupplierDtoSortInput!]",
        isList: true,
        pageInfo: pageInfo,
        enablePaging: enablePaging,
      }).then(function(result) {
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const deleteSupplier = ({ id }) => (dispatch) => {
  const supplier = { supplierId: id };
  return new Promise(function(resolve, reject) {
    try {
      const deleteCommand = {
        supplierId: supplier.supplierId,
      };
      mutate({
        command: "deleteSupplier",
        commandType: "DeleteSupplierCommandInput",
        data: deleteCommand,
        columns: {},
      }).then(function(result) {
        dispatch(recordDeleteSuccess("supplier", supplier));
        resolve(result);
      });
    } catch (err) {
      dispatch(recordDeleteFailed("supplier", err));
      reject(err);
    }
  });
};
